import React, {useRef, useState} from "react";
import {ActionIcon, Button, LoadingOverlay, Menu, Modal, useMantineTheme} from "@mantine/core";
import {IconPrinter} from "@tabler/icons-react";
import {useReactToPrint} from 'react-to-print';
import DocumentToPrint from "./DocumentToPrint";
import {useLazyGetGuaranteeTextQuery, useLazyReadOrderQuery} from "../../api/order";
import {IOrder} from "../../api/types";
import {useLazyReadOrderHistoryQuery} from "../../api/orderHistory";

export interface IOrderForPrint extends IOrder {
    guaranteeText?: string,
    doneComment?: string
}

const PrintOrder = (props: { orderId: string, status: string }) => {
    const documentRef = useRef<any>();
    const [loading, setLoading] = useState(false);
    const [printType, setPrintType] = useState<string | undefined>()
    const [printWithStamp, setPrintWithStamp] = useState<boolean>(false);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
    const [orderRecord, setOrderRecord] = useState<IOrderForPrint | undefined>()
    const [readOrder] = useLazyReadOrderQuery();
    const [readGuaranteeText] = useLazyGetGuaranteeTextQuery()
    const [readOrderHistory] = useLazyReadOrderHistoryQuery();
    const handlePrint = useReactToPrint({
        content: () => documentRef.current,
        onAfterPrint: () => {
            //clear all states
            setPrintWithStamp(false)
            setOrderRecord(undefined)
            setPrintType(undefined)
        }
    });

    function onSelectPrintType(type: string) {
        setPrintType(type);
        setOpenConfirmation(true);
    }

    async function onSelectStampDecision(decision: boolean) {
        setPrintWithStamp(decision);
        setLoading(true)
        const order = await readOrder({id: props.orderId}).unwrap();
        const guaranteeText = await readGuaranteeText({id: props.orderId}).unwrap()
        const orderHistory = await readOrderHistory({orderId: props.orderId}).unwrap();
        const findForDone = orderHistory.history.filter(v => v.status === 'done');
        setOrderRecord({
            ...order,
            readyDate: order.readyDate ? order.readyDate : new Date().toLocaleDateString(),
            guaranteeText: guaranteeText.text,
            doneComment: findForDone.length ? findForDone[findForDone.length - 1].comment : "",
        });
        setTimeout(() => {
            setOpenConfirmation(false);
            setLoading(false)
            handlePrint();
        }, 2000)

    }

    return (
        <>
            <PrintMenu
                onClick={onSelectPrintType}
                guaranteeDisabled={props.status !== 'payed'}
            />
            <DocumentToPrint
                docRef={documentRef}
                type={printType}
                orderRecord={orderRecord}
                addStamp={printWithStamp}
            />
            <StampConfirmationDialog
                opened={openConfirmation}
                onClose={() => setOpenConfirmation(false)}
                onSelectDecision={onSelectStampDecision}
                loading={loading}
            />
        </>
    )
}

export default PrintOrder;

const PrintMenu = (props: { onClick: (v: string) => void, guaranteeDisabled: boolean }) => {
    return (
        <Menu trigger="hover" openDelay={100} closeDelay={400}>
            <Menu.Target>
                <ActionIcon ml={5} variant="outline" color={'blue'} size={'sm'}>
                    <IconPrinter size="1.2rem"/>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item onClick={(e) => {
                    e.stopPropagation();
                    props.onClick('reception')
                }}>
                    Печать приёмки
                </Menu.Item>
                <Menu.Item onClick={(e) => {
                    props.onClick('guarantee')
                    e.stopPropagation();
                }} disabled={props.guaranteeDisabled}>
                    Печать гарантии
                </Menu.Item>
                {/* Other items ... */}
            </Menu.Dropdown>
        </Menu>
    )
}


const StampConfirmationDialog = (props: {
    opened: boolean,
    onClose: () => void,
    onSelectDecision: (a: boolean) => void,
    loading: boolean
}) => {
    const theme = useMantineTheme();
    return (
        <Modal opened={props.opened} onClose={props.onClose} title={'Добавить печать?'}
               centered
               overlayProps={{
                   color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                   opacity: 0.55,
                   blur: 3,
               }}
        >
            <LoadingOverlay visible={props.loading} overlayBlur={2}/>
            <Button onClick={(e) => {
                e.stopPropagation();
                props.onSelectDecision(true)
            }}>
                Да
            </Button>
            <Button onClick={(e) => {
                e.stopPropagation();
                props.onSelectDecision(false)
            }} ml={10}>
                Нет
            </Button>
        </Modal>
    )
}

import phoneLogo from './phone.gif'
import logo from './logo.jpg'
import stamp from './stamp.png'
import dayjs from "dayjs";
import {IOrderForPrint} from "./printOrder";
import "./print.css"

const Reception = (props: { order: IOrderForPrint, addStamp: boolean }) => {
    return (
        <div className={"print-reception-wrapper"}>
            <style type="text/css">
                {`@media print{@page {
                    size: A4 landscape;
                    margin: 5mm;
                 }`}
            </style>
            <div className={"print-reception-firstTable"}>
                <TableFirstHeader/>
                <TableFirstBody
                    order={props.order}
                    addStamp={props.addStamp}
                />
            </div>
            <div className={"print-reception-separator"}> &nbsp; </div>
            <div className={"print-reception-secondTable"}>
                <div>Копия сервисного центра</div>
                <TableSecondBody
                    order={props.order}
                />
            </div>
        </div>
    )
}

export default Reception

const TableFirstHeader = () => {
    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <img src={logo} width={230} alt={'logo'}/>
                </div>
                <div style={{display: 'flex', alignItems: 'flex-start', paddingRight: 15}}>
                    <img src={phoneLogo} height={30} alt={'phone'}/>
                    <div className={'print-reception-phoneNumber'}>+7 (495) 133-98-96</div>
                </div>
            </div>
            <div style={{textAlign: 'right', paddingRight: 15, fontSize: 14}}>
                Садовая-Сухаревская 2/34с1 офис 301
            </div>
            <hr style={{background: 'black', color: 'black'}}/>
        </>
    )
};


const TableFirstBody = (props: { order: IOrderForPrint, addStamp: boolean }) => {
    const {order: {orderNo, client, createdAt, device, problem}, addStamp} = props;
    return (
        <div>
            <div style={{display: 'flex'}}>
                <div style={{width: '70%'}}>
                    <table style={{fontSize: 13}}>
                        <tbody>
                        <tr>
                            <td>Договор на ремонт:</td>
                            <td>CV<b>{orderNo}</b></td>
                        </tr>
                        <tr>
                            <td>Имя, Фамилия:</td>
                            <td>{client.name}</td>
                        </tr>
                        <tr>
                            <td>Телефон:</td>
                            <td>{client.phone}</td>
                        </tr>
                        <tr>
                            <td>Дата:</td>
                            <td>{dayjs(createdAt).format('DD-MM-YYYY HH:mm:ss')}</td>
                        </tr>
                        <tr>
                            <td>Устройство:</td>
                            <td>{device.brand.name} {device.model.name}</td>
                        </tr>
                        <tr>
                            <td>Серийный номер:</td>
                            <td>{device.serial}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{width: '30%'}}>
                    <table style={{fontSize: 13}}>
                        <thead>
                        <tr>
                            <th>Наличие:</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                            </td>
                            <td>
                                да нет
                            </td>
                        </tr>
                        <tr>
                            <td>АКБ</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>БП</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Царапины</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Вмятины</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <p style={{fontSize: 12}}><b>Заявленная неисправность: </b></p>
                <p style={{fontSize: 12, lineHeight: 1}}>{problem}</p>
            </div>
            <hr style={{width: '100%', marginBottom: 10, marginTop: 5}}/>
            <Rules order={props.order} addStamp={addStamp}/>
        </div>
    )
};
const TableSecondBody = (props: { order: IOrderForPrint }) => {
    const {order: {orderNo, client, createdAt, device, problem, devicePassword}} = props;
    return (
        <div>
            <div style={{display: 'flex'}}>
                <div style={{width: '70%'}}>
                    <table style={{fontSize: 13}}>
                        <tbody>
                        <tr>
                            <td>Договор на ремонт:</td>
                            <td>CV<b>{orderNo}</b></td>
                        </tr>
                        <tr>
                            <td>Имя, Фамилия:</td>
                            <td>{client.name}</td>
                        </tr>
                        <tr>
                            <td>Телефон:</td>
                            <td>{client.phone}</td>
                        </tr>
                        <tr>
                            <td>Дата:</td>
                            <td>{dayjs(createdAt).format('DD-MM-YYYY HH:mm:ss')}</td>
                        </tr>
                        <tr>
                            <td>Устройство:</td>
                            <td>{device.brand.name} {device.model.name}</td>
                        </tr>
                        <tr>
                            <td>Пароль:</td>
                            <td>{devicePassword}</td>
                        </tr>
                        <tr>
                            <td>Серийный номер:</td>
                            <td>{device.serial}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{width: '30%'}}>
                    <table style={{fontSize: 13}}>
                        <thead>
                        <tr>
                            <th>Наличие:</th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                            </td>
                            <td>
                                да нет
                            </td>
                        </tr>
                        <tr>
                            <td>АКБ</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>БП</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Царапины</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Вмятины</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: 20, height: 20, border: '1px solid black'}}>

                                    </div>
                                    <div style={{width: 20, height: 20, marginLeft: 2, border: '1px solid black'}}>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <p style={{fontSize: 13}}><b>Заявленная неисправность: </b></p>
                <p style={{fontSize: 13, lineHeight: 1}}>{problem}</p>
            </div>
            <hr style={{width: '100%', marginBottom: 10, marginTop: 5}}/>
            <Rules order={props.order} addStamp={false}/>
        </div>
    )
};
const Rules = (props: { order: IOrderForPrint, addStamp: boolean }) => {
    const {order: {client, manager}, addStamp} = props;
    return (
        <>
            <div>
                <ol type="1" start={1} style={{fontSize: 11, lineHeight: 1.2}}>
                    <li>Предварительная диагностика или ремонт производится в срок от 20 минут до 5 дней. Если требуемые
                        запчасти необходимо заказать у производителя, то срок ремонта может быть увеличен до 45 дней.
                    </li>
                    <li>Ремонт выполняется только тех неисправностей, которые указаны в квитанции.</li>
                    <li>СЦ не несет никакой ответственности за сохранность личных данных на жестком диске, контактов и
                        информации в памяти устройства, SIM-карт, карт FLASH памяти, чехлов, батареек, аксессуаров.
                    </li>
                    <li>Гарантия распространяется только на проведенные работы и установленные запчасти.</li>
                    <li>Гарантия не распространяется на устройства, неисправности которых были вызваны механическими
                        повреждениями или попаданием влаги.
                    </li>
                    <li>Аппарат принимается без проведения диагностики, как есть. Сдавая аппарат в ремонт, клиент
                        соглашается, что все неисправности, которые будут обнаружены во время ремонта возникли перед
                        сдачей аппарата в ремонт.
                    </li>
                    <li>Бесплатное хранение отремонтированной техники в нашем сервисе осуществляется первые 15 дней. За
                        хранение техники после 16 марта 2021 г. СЦ взимает плату в размере 100 рублей за каждые сутки.
                    </li>
                    <li>Я даю свое согласие на обработку персональных данных, содержащихся в настоящей анкете, в целях
                        направления информации о статусе заказа а также стоимости услуг ИП Тучак С.С.
                    </li>
                </ol>
            </div>
            <table style={{fontSize: 14, width: '100%', marginTop: 20}}>
                <tbody>
                <tr>
                    <td width="30%">
                        С условиями согласен:
                    </td>

                    <td align="right" width="30%">
                        _________________/
                    </td>
                    <td>{client.name}</td>
                    <td width="10%">/</td>
                </tr>
                <tr>
                    <td><br/></td>
                </tr>
                <tr>
                    <td width="30%" style={{position: 'relative'}}>
                        Принял:
                        {addStamp && (
                            <img alt={'stamp'} src={stamp} width={100}
                                 style={{position: 'absolute', top: -10, left: 200}}/>
                        )}
                    </td>
                    <td align="right" width="30%">
                        _________________/
                    </td>
                    <td>{manager.name}</td>
                    <td width="10%">/</td>
                </tr>
                </tbody>
            </table>
        </>
    )
};


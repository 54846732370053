import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useReadOrderHistoryQuery} from "../api/orderHistory";
import {Modal, Timeline, Text, Skeleton} from "@mantine/core";
import {closeOrderHistory} from "../app/orders.slice";
import {statusObjects} from "./ordersTable/OrdersTable";
import {IStatus} from "../api/types";

const OrderHistory = () => {
    const {orderHistory} = useAppSelector(state => state.order)
    const dispatch = useAppDispatch();
    const {data, isFetching} = useReadOrderHistoryQuery({orderId: orderHistory.orderId}, {skip: !orderHistory.opened});

    return (
        <Modal opened={orderHistory.opened}
               zIndex={300}
               onClose={() => {
                   dispatch(closeOrderHistory())
               }}
               centered
               title={`История заказа № ${data?.orderNo}`}
        >
            {isFetching ? (
                <>
                    <Skeleton height={15} radius="xl"/>
                    <Skeleton height={15} radius="xl" mt={6}/>
                    <Skeleton height={15} radius="xl" mt={6}/>
                    <Skeleton height={15} radius="xl" mt={6}/>
                </>
            ) : (
                <Timeline
                    lineWidth={2}
                >
                    {data?.history.map(historyItem => {
                        const statusItem = statusObjects[historyItem.status as IStatus]
                        return (
                            <Timeline.Item
                                title={new Date(historyItem.date).toLocaleString()}
                                bulletSize={22}
                                active
                                color={`${statusItem.color}.8`}
                            >
                                <Text color="dimmed" size="sm" weight={'500'}>
                                    Статус: <Text component="span" color="dimmed" size="sm"
                                                  weight={'300'}>{statusItem.text}</Text>
                                </Text>
                                {historyItem.status === 'outsource' && (
                                    <Text color="dimmed" size="sm" weight={'500'}>
                                        Сервис: <Text component="span" color="dimmed" size="sm"
                                                      weight={'300'}>{historyItem.outsource?.name}</Text>
                                    </Text>
                                )}
                                <Text color="dimmed" size="sm" weight={'500'}>
                                    Менеджер: <Text component="span" color="dimmed" size="sm"
                                                    weight={'300'}>{historyItem.manager?.name}</Text>
                                </Text>
                                <Text color="dimmed" size="sm" weight={'500'}>
                                    Мастер: <Text component="span" color="dimmed" size="sm"
                                                  weight={'300'}>{historyItem.executor?.name}</Text>
                                </Text>
                                {historyItem.price && (
                                    <Text color="dimmed" size="sm" weight={'500'}>
                                        Цена: <Text component="span" color="dimmed" size="sm"
                                                    weight={'300'}>{historyItem.price}</Text>
                                    </Text>
                                )}
                                <Text color="dimmed" size="sm" weight={'500'}>
                                    Комментарий: <Text component="span" color="dimmed" size="sm"
                                                       weight={'300'}>{historyItem.comment}</Text>
                                </Text>

                                {(historyItem.status === 'done') && (
                                    <>
                                        <Text color="dimmed" size="sm" weight={'500'}>
                                            Затраты: <Text component="span" color="orange.5" size="sm"
                                                           weight={'300'}>{historyItem.otherExpenses} ₽</Text>
                                        </Text>
                                        <Text color="dimmed" size="sm" weight={'500'}>
                                            Описание затрат: <Text component="span" color="orange.5" size="sm"
                                                                   weight={'300'}>{historyItem.otherExpensesComment}</Text>
                                        </Text>
                                    </>
                                )}
                            </Timeline.Item>
                        )
                    })}
                </Timeline>
            )}
        </Modal>
    )
}
export default OrderHistory;
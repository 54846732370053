import {Divider} from "@mantine/core";
import phoneLogo from './phone.gif'
import logo from './logo.jpg'
import stamp from './stamp.png'
import {IOrder} from "../../api/types";
import {IOrderForPrint} from "./printOrder";

const Guarantee = (props: { order: IOrder & {guaranteeText?: string}, addStamp: boolean }) => {
    const {order, addStamp} = props;
    return (
        <div>
            <Header/>
            <Body
                addStamp={addStamp}
                order={order}
            />
        </div>
    )
}

export default Guarantee


const Header = () => {
    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <img src={logo} width={230} alt={'logo'}/>
                </div>
                <div style={{display: 'flex', alignItems: 'flex-start', paddingRight: 15}}>
                    <img src={phoneLogo} height={30} alt={'phone'}/>
                    <div className={'print-reception-phoneNumber'}>+7 (495) 133-98-96</div>
                </div>
            </div>
            <div style={{textAlign: 'right', paddingRight: 15, fontSize: 12}}>
                Садовая-Сухаревская 2/34с1 офис 301
            </div>
            <hr style={{background: 'black', color: 'black'}}/>
        </>
    )
};

const Body = (props: { order: IOrderForPrint, addStamp: boolean }) => {
    const {order: {client, device, readyDate, problem, orderNo, doneComment, price, guaranteeText}, addStamp} = props;
    return (
        <div>
            <p style={{fontSize: 12}}>
                <b style={{fontSize: 12}}>Акт выполненных работ по договору № CV{orderNo}</b>
            </p>
            <table width={'100%'}>
                <tbody>
                <tr>
                    <td>
                        Устройство:
                    </td>
                    <td>{`${device.brand.name} ${device.model.name}`}</td>
                    <td>
                        Дата выдачи:
                    </td>
                    <td>
                        {readyDate}
                    </td>
                </tr>
                <tr>
                    <td colSpan={5}>&nbsp;</td>
                </tr>
                <tr>
                    <td>
                        Серийный номер:
                    </td>
                    <td>
                        {device.serial}
                    </td>
                    <td>
                        Имя клиента:
                    </td>
                    <td>
                        {client.name}
                    </td>
                </tr>
                <tr>
                    <td colSpan={5}>&nbsp;</td>
                </tr>
                <tr>
                    <th></th>
                    <td></td>
                    <td>Телефон:</td>
                    <td>+{client.phone}</td>
                </tr>
                </tbody>
            </table>
            <Divider/>
            <div>
                <p style={{fontSize: 12}}><b>Заявленная неисправность:</b></p>
                <p style={{fontSize: 12}}>{problem}</p>
                <br/>
                <br/>
                <br/>
            </div>
            <div>
                <p style={{fontSize: 12}}><b>Заключение специалиста и выполненные работы:</b></p>
                <p style={{fontSize: 12}}>{doneComment}</p>
                <br/>
                <br/>
                <br/>

            </div>
            <div>
                <p style={{fontSize: 12}}>Стоимость работ: {new Intl.NumberFormat('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                }).format(price)}</p>

                <p style={{fontSize: 12}}><b>Гарантия: {guaranteeText}</b></p>
            </div>
            <div style={{fontSize: 12}}>
                Гарантия распространяется только на проведенные работы и установленные запчасти. Гарантия не
                распространяется на программное обеспечение. Внимание, ваш аппарат опломбирован. Нарушение гарантийных
                пломб аннулирует нашу гарантию.
                <br/>
                Устройство проверено в моем присутствии, вышеперечисленные услуги выполнены полностью, претензий по
                объему, качеству и срокам оказания услуг не имею.
            </div>
            <br/>
            <br/>
            <div>
                <table width={'100%'}>
                    <tbody>
                    <tr>
                        <th>
                            Заказчик:
                        </th>
                        <td>
                            ___________________
                        </td>
                        <td colSpan={5}> &nbsp; </td>
                        <th style={{position: 'relative'}}>
                            Исполнитель:
                            {addStamp && (
                                <img alt={'stamp'} src={stamp} width={100} style={{position: 'absolute', top: -30, left: 200}}/>
                            )}
                        </th>
                        <td>
                            ___________________
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
};

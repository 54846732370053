import {
    ActionIcon, Box,
    CopyButton,
    createStyles, Divider, Flex,
    LoadingOverlay,
    Pagination,
    ScrollArea,
    Table, Text, TextInput,
    Tooltip
} from "@mantine/core";
import {useDebouncedState, useMediaQuery, usePagination} from '@mantine/hooks';
import {useGetOrdersQuery} from "../../api/order";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {IconCheck, IconCopy, IconHistory, IconSearch} from "@tabler/icons-react";
import React, {useCallback, useEffect, useState} from "react";
import OrderStatusForm from "../orderStatusForm/OrderStatusForm";
import {IOrder, IStatus} from "../../api/types";
import OrderByClient from "../OrderByClient";
import Th from "./Th";
import {useNavigate} from "react-router-dom";
import {openOrderHistory} from "../../app/orders.slice";
import PrintOrder from "../printOrder/printOrder";
import MobileOrders from "./MobileOrders";
import DateItem from "./DateItem";


const defaultValues = {
    totalPages: 0,
    content: [],
    page: 0
}
export const defaultFilterStatuses = ["NEW", "inProgress", "outsource", "done", "diagnosed", "approval", "waitForPieces", "needAttention"];


interface IClient {
    name: string,
    phone: string
    _id: string
}

interface IColumn {
    name: 'orderNo' | 'createdAt' | 'client' | 'device' | 'problem' | 'price' | 'status' | 'readyDate' | 'expenses',
    label: string | React.ReactNode,
    type?: string,
    width?: string,
    render?: (string: any, {}: any) => any,
    sortable?: boolean,
}

interface IDevice {
    brand: {
        name: string
    },
    model: {
        name: string,
    },
    type: {
        name: string
    },
    serial: string
}

const useStyles = createStyles((theme) => ({
    paginationContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '20px 5px',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    },
    tableHead: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    },
    table: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    },
    clientNameText: {
        '&:hover': {
            textDecoration: "underline"
        }
    },
    noWrapText: {
        textWrap: 'nowrap'
    }
}))

const initialStatusForm = {opened: false}

interface IProps {
    filter?: {
        "client._id"?: string,
        status?: string | string[],
        readyDate?: { "$gt": string, "$lt": string }
    }
}

const OrdersTable = (props: IProps) => {
    const {classes} = useStyles();
    const isSmallScreen = useMediaQuery('(max-width: 500px)');
    const navigate = useNavigate();
    const [search, setSearch] = useDebouncedState("", 200);
    const [sort, setSort] = useState<{ column: string, direction: string }>({
        column: "createdAt",
        direction: "descend"
    });
    const [columns, setColumns] = useState<IColumn[]>([])
    const [page, onChangePage] = useState(1);
    const [statusForm, setStatusForm] = useState<{ opened: boolean, record?: IOrder }>(initialStatusForm);
    const [ordersByClient, setOrdersByClient] = useState<{ opened: boolean, record?: IOrder }>(initialStatusForm);

    const selectedOffice = useAppSelector(state => state.authorization.selectedOffice)
    const {data, isFetching} = useGetOrdersQuery({
        page: page,
        pageSize: 10,
        office: selectedOffice,
        search: search,
        readyDate: props.filter?.readyDate ? props.filter.readyDate : undefined,
        status: props.filter?.status === 'unset' ? undefined : props.filter?.status || defaultFilterStatuses,
        sort,
        client: props.filter?.["client._id"]
    });

    const pagination = usePagination({total: data?.totalPages || 0, initialPage: 1, page, onChange: onChangePage})

    const openStatusForm = useCallback((record: IOrder) => {
        setStatusForm({opened: true, record})
    }, []);

    const openOrdersByClient = useCallback((record: IOrder) => {
        setOrdersByClient({opened: true, record})
    }, []);

    function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearch(event.target.value)
    }

    useEffect(() => {
        if (props.filter?.status?.[0] === 'payed') {
            setColumns(c.payed)
            setSort({column: "readyDate", direction: "descend"})
        } else {
            setColumns(c.default)
            setSort({column: "createdAt", direction: "descend"})
        }
    }, [props.filter?.status?.[0]])

    const c: { default: IColumn[], payed: IColumn[] } = {
        default: [
            {name: 'orderNo', label: '№', type: 'number', width: '50px'},
            {
                name: 'createdAt', label: 'Дата', type: 'date', width: '130px', sortable: true,
                render: (data: Date) => {
                    return (
                        <DateItem data={data}/>
                    )
                }
            },
            {
                name: 'client', label: 'Клиент', render: (data: IClient, rowItem: IOrder) => {
                    return (
                        <div onClick={(e) => e.stopPropagation()}>
                            <Text
                                className={classes.clientNameText}
                                weight={500} style={{cursor: "pointer"}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!props.filter?.["client._id"]) {
                                        openOrdersByClient(rowItem)
                                    }
                                }}
                            >{data?.name},</Text>
                            <a href={`tel:${data?.phone}`}>+{data?.phone}</a>
                        </div>
                    )
                }
            },
            {
                name: 'device', label: 'Устройство', render: (data: IDevice) => {
                    return (
                        <>
                            {data.type?.name} {data.brand?.name} {data.model?.name}<br/>
                            <div style={{borderBottom: '1px solid rgba(0,0,0,.06)'}}></div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{marginRight: 3}}>
                                    {data.serial}
                                </div>
                                <CopyButton value={data.serial}>
                                    {({copied, copy}) => (
                                        <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                            <ActionIcon color={copied ? 'teal' : 'blue'} onClick={e => {
                                                e.stopPropagation();
                                                copy()
                                            }}
                                                        style={{color: 'cyan.6'}}>
                                                {copied ? <IconCheck size="1rem"/> : <IconCopy size="1rem"/>}
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                </CopyButton>
                            </div>
                        </>
                    )
                }
            },
            {name: 'problem', label: 'Неисправность'},
            {name: 'price', label: 'Цена', render: (data: string) => (<>{data} ₽</>), sortable: true, width: '130px'},
            {
                name: 'status', label: 'Статус', width: '150px', render: (data: IStatus, rowItem: IOrder) => {
                    return (
                        <StatusCell
                            data={data}
                            openStatusForm={() => openStatusForm(rowItem)}
                            _id={rowItem._id}
                        />
                    )
                }
            },
        ],
        payed: [
            {name: 'orderNo', label: '№', type: 'number', width: '50px'},
            {
                name: 'readyDate', label: 'Выдан', type: 'date', width: '130px', sortable: true,
                render: (data: Date) => {
                    return (
                        <span style={{whiteSpace: "nowrap"}}>
                            {new Date(data).toLocaleDateString()}
                        </span>
                    )
                }
            },
            {
                name: 'client', label: 'Клиент', render: (data: IClient, rowItem: IOrder) => {
                    return (
                        <div onClick={(e) => e.stopPropagation()}>
                            <Text
                                className={classes.clientNameText}
                                weight={500} style={{cursor: "pointer"}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!props.filter?.["client._id"]) {
                                        openOrdersByClient(rowItem)
                                    }
                                }}
                            >{data?.name},</Text>
                            <a href={`tel:${data?.phone}`}>+{data?.phone}</a>
                        </div>
                    )
                }
            },
            {
                name: 'device', label: 'Устройство', render: (data: IDevice) => {
                    return (
                        <>
                            {data.type?.name} {data.brand?.name} {data.model?.name}<br/>
                            <div style={{borderBottom: '1px solid rgba(0,0,0,.06)'}}></div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{marginRight: 3}}>
                                    {data.serial}
                                </div>
                                <CopyButton value={data.serial}>
                                    {({copied, copy}) => (
                                        <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                            <ActionIcon color={copied ? 'teal' : 'blue'} onClick={e => {
                                                e.stopPropagation();
                                                copy()
                                            }}
                                                        style={{color: 'cyan.6'}}>
                                                {copied ? <IconCheck size="1rem"/> : <IconCopy size="1rem"/>}
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                </CopyButton>
                            </div>
                        </>
                    )
                }
            },
            {name: 'problem', label: 'Неисправность'},
            {
                name: 'price',
                label: <Box ta={'right'}>Цена <Divider/> Расход</Box>,
                sortable: false,
                width: '130px',
                render: (data: string, record) => (
                    <Box ta={'right'}><Text c={'green.5'}>{record.price} ₽</Text>
                        <Divider/> {record.expenses || 0} ₽</Box>),
            },
            {
                name: 'expenses', label: 'Доход', render: (_, record) => {
                    return (
                        <div className={classes.noWrapText}>{record.price - record.expenses} ₽</div>
                    )
                }
            },
            {
                name: 'status', label: 'Статус', width: '150px', render: (data: IStatus, rowItem: IOrder) => {
                    return (
                        <StatusCell
                            data={data}
                            paymentType={rowItem.paymentType}
                            openStatusForm={() => openStatusForm(rowItem)}
                            _id={rowItem._id}
                        />
                    )
                }
            },
        ]
    }

    return (
        <>
            <TextInput
                placeholder="Поиск по всем полям (телефон, бренды, модели и т.д)"
                mb="md"
                icon={<IconSearch size="0.9rem" stroke={1.5}/>}
                onChange={handleSearchChange}
            />
            {isSmallScreen ?
                <MobileOrders
                    data={data}
                    openStatusForm={openStatusForm}
                    openOrdersByClient={openOrdersByClient}
                />
                : (
                <ScrollArea>
                    <LoadingOverlay visible={isFetching} overlayBlur={1}/>
                    <Table highlightOnHover className={classes.table} withColumnBorders>
                        <thead className={classes.tableHead}>
                        <tr>
                            {columns.map(column => (
                                <Th
                                    key={column.name}
                                    sortable={Boolean(column.sortable)}
                                    width={column.width || 'auto'}
                                    onSort={() => setSort(state => {
                                        return {
                                            column: column.name,
                                            direction: state.column === column.name ?
                                                state.direction === 'ascend' ? 'descend' : 'ascend' : "ascend"
                                        }
                                    })}
                                    sorted={Boolean(sort.column === column.name && sort.direction)}
                                    reversed={Boolean(sort.column === column.name && sort.direction === 'descend')}
                                >
                                    {column.label}
                                </Th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {(data || {content: []}).content.map((item: IOrder, i) => {
                            return (
                                <tr key={i} onClick={() => {
                                    setOrdersByClient(initialStatusForm)
                                    navigate(`/order/${item?._id}`)
                                }}>
                                    {columns.map((column: IColumn) => {
                                        return (
                                            <td key={column.name}>
                                                {
                                                    column.render ?
                                                        column.render(item[column.name], item) :
                                                        item[column.name]
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    <div className={classes.paginationContainer}>
                        <Pagination
                            total={(data || {totalPages: 0}).totalPages}
                            onChange={(value) => {
                                pagination.setPage(value)
                            }}
                        />
                    </div>
                </ScrollArea>
            )}

            <OrderStatusForm record={statusForm.record} opened={statusForm.opened}
                             close={() => setStatusForm(initialStatusForm)}/>
            <OrderByClient order={ordersByClient.record} opened={ordersByClient.opened}
                           onClose={() => setOrdersByClient(initialStatusForm)}/>
        </>
    )
}
export default OrdersTable;


export const statusObjects = {
    NEW: {
        color: 'blue',
        text: 'Новый',
    },
    diagnosed: {
        color: 'orange',
        text: 'Продиагностирован',
    },
    approval: {
        color: 'cyan',
        text: 'На согласовании',
    },
    needAttention: {
        color: 'red',
        text: 'Требует Внимание',
    },
    waitForPieces: {
        color: 'yellow',
        text: 'Ожидание з/ч',
    },
    inProgress: {
        color: 'violet',
        text: 'В работе',
    },
    outsource: {
        color: 'grape',
        text: 'Аутсорс',
    },
    done: {
        color: 'brown',
        text: 'Готов',
    },
    refused: {
        color: 'red',
        text: 'Отказ',
    },
    payed: {
        color: 'green',
        text: 'Оплачен'
    }
}
const paymentTypeLocale = {
    card: {
        text: "безнал",
        color: "orange.3"
    },
    cash: {
        text: "нал",
        color: "blue.3"
    },
    transfer: {
        text: "безнал к.",
        color: 'red.3'
    }
}
const useStatusStyles = createStyles((theme, type: IStatus) => ({
    root: {
        backgroundColor: theme.colors[statusObjects[type].color][9],
        color: theme.colors.gray[0],
        cursor: "pointer",
        maxWidth: 150,
        padding: 2
    }
}))

export const StatusCell = ({data, paymentType, openStatusForm, _id}: {
    data: IStatus,
    paymentType?: string,
    openStatusForm: () => void,
    _id: string
}) => {
    const {classes} = useStatusStyles(data)
    const dispatch = useAppDispatch();
    return (
        <Flex align={'center'} justify={'space-between'}>
            <Box className={classes.root}
                 px={10}
                 w={'100%'}
                 style={{textAlign: 'center'}}
                 onClick={(e) => {
                     e.stopPropagation();
                     openStatusForm()
                 }}>
                <Text size={'xs'}>{statusObjects[data].text}</Text>
                {(data === 'payed' && paymentType) &&
                    <Text ta={'center'} size={'xs'} style={{whiteSpace: "nowrap"}}
                          c={paymentTypeLocale[paymentType as keyof typeof paymentTypeLocale].color}>
                        {paymentTypeLocale[paymentType as keyof typeof paymentTypeLocale].text}
                    </Text>
                }
            </Box>
            <Flex ml={5} align={'center'}>
                <Tooltip label={'История заказа'}>
                    <ActionIcon
                        variant={"outline"}
                        color={'indigo.5'}
                        p={1}
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(openOrderHistory(_id))
                        }} size={"sm"}>
                        <IconHistory size={22}/>
                    </ActionIcon>
                </Tooltip>
                <PrintOrder orderId={_id} status={data}/>
            </Flex>
        </Flex>
    )
}

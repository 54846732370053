import {Checkbox, Modal, Paper, ScrollArea, Text} from "@mantine/core";
import {IOrder} from "../api/types";
import OrdersTable, {defaultFilterStatuses} from "./ordersTable/OrdersTable";
import {useState} from "react";

const OrderByClient = (props: { order?: IOrder, opened: boolean, onClose: () => void }) => {
    const [onlyInWork, setOnlyInWork] = useState(false)

    if (!props.order) {
        return <></>
    }

    return (
        <Modal
            opened={props.opened}
            onClose={props.onClose}
            centered
            title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Text weight={500}>{props.order?.client.name}</Text>,
                    <Text c={'cyan.5'} pl={5}>
                        <a href={`tel:${props.order?.client.phone}`}
                           style={{color: 'inherit'}}
                        >+{props.order?.client.phone}</a>
                    </Text>
                </div>}
            size={'100%'}
            zIndex={199}
            padding={"sm"}
        >
            <Paper p={10}>
                <Paper>
                    <Checkbox
                        label={'Показать только в работе'}
                        onChange={e => setOnlyInWork(e.target.checked)}
                        checked={onlyInWork}
                    />
                </Paper>
                <ScrollArea>
                    <Paper mt={20} py={10}>
                        <OrdersTable
                            filter={{
                                "client._id": props.order?.client._id || "",
                                status: onlyInWork ? defaultFilterStatuses : 'unset'
                            }}
                        />
                    </Paper>
                </ScrollArea>
            </Paper>

        </Modal>
    )
}
export default OrderByClient;
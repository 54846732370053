import {IOrderQuery} from "../../api/order";
import {ActionIcon, Card, CopyButton, createStyles, Divider, Flex, Text, Tooltip} from "@mantine/core";
import DateItem from "./DateItem";
import {StatusCell} from "./OrdersTable";
import {IOrder} from "../../api/types";
import React from "react";
import {IconCheck, IconCopy} from "@tabler/icons-react";

function convertToRub(v: number): string {
    return new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB'
    }).format(v)
}

const useStyles = createStyles(() => ({
    container: {
        display: "flex",
        gap: 10,
        flexDirection: "column",
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 10,
    },
    itemHead: {}
}))
const MobileOrders = ({data, openStatusForm, openOrdersByClient}: {
    data: IOrderQuery | undefined,
    openStatusForm: (item: IOrder) => void,
    openOrdersByClient: (item: IOrder) => void
}) => {
    const {classes} = useStyles();
    return (
        <div className={classes.container}>
            {data?.content.map(item => {
                return (
                    <Card key={item._id} className={classes.item} shadow={"sm"}>
                        <Flex justify={"space-between"} align={"center"} className={classes.itemHead} pb={10}>
                            <div style={{fontSize: 14, position: 'relative'}}>
                                <DateItem data={new Date(item.createdAt)}/>
                                <Text color={"dimmed"}>{convertToRub(item.price)}</Text>
                                {item.status === 'payed' && (
                                    <>
                                        <Text style={{lineHeight: 0.5}}>{convertToRub(item.expenses)}</Text>
                                        <div style={{
                                            position: 'absolute',
                                            right: -10,
                                            top: 26
                                        }}>
                                            -
                                        </div>
                                        <div style={{borderTop: '1px solid #bebebe', width: '100%', marginTop: 5}}>
                                            <Text style={{lineHeight: 1.2}}
                                                  color={'green.8'}>
                                                {convertToRub(item.price - item.expenses)}
                                            </Text>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div>
                                <StatusCell data={item.status} openStatusForm={() => openStatusForm(item)}
                                            _id={item._id}/>
                            </div>
                        </Flex>
                        <Divider my={10}/>
                        <div onClick={() => openOrdersByClient(item)}>
                            <Text color={"dimmed"} size={"sm"}>Клиент </Text>
                            <Flex justify={"space-between"} align={"center"}>
                                <Text>{item.client.name} </Text>
                                <Text><a
                                    href={`tel:${item.client.phone}`}>+{item.client.phone}</a></Text>
                            </Flex>
                        </div>
                        <Divider my={10}/>
                        <div>
                            <Text color={"dimmed"} size={"sm"}>Устройство </Text>
                            <Flex justify={"space-between"} align={"center"}>
                                <div>
                                    {item.device.type?.name} {item.device.brand?.name} {item.device.model?.name}
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{marginRight: 3}}>{item.device.serial}</div>
                                    <CopyButton value={item.device.serial}>
                                        {({copied, copy}) => (
                                            <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                                <ActionIcon color={copied ? 'teal' : 'blue'} onClick={e => {
                                                    e.stopPropagation();
                                                    copy()
                                                }}
                                                            style={{color: 'cyan.6'}}>
                                                    {copied ? <IconCheck size="1.2rem"/> : <IconCopy size="1.2rem"/>}
                                                </ActionIcon>
                                            </Tooltip>
                                        )}
                                    </CopyButton>
                                </div>
                            </Flex>
                        </div>
                        <Divider my={10}/>
                        <div>
                            <Text color={"dimmed"} size={"sm"}>Неисправность </Text>
                            <Text>{item.problem}</Text>
                        </div>
                    </Card>
                )
            })}
        </div>
    )
}

export default MobileOrders;
import React, {useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {createStyles} from "@mantine/core";
import {DatePickerInput} from "@mantine/dates";
import dayjs from "dayjs";
import Statistics from "./Statistics";
import OrdersTable from "../../components/ordersTable/OrdersTable";
import 'dayjs/locale/ru';

const useStyles = createStyles((theme) => ({
    tableContainer: {
        padding: `0 calc(${theme.spacing.xl} * 1.5) calc(${theme.spacing.xl} * 1.5) calc(${theme.spacing.xl} * 1.5)`,
        [theme.fn.smallerThan('md')]: {
            padding: `0 calc(${theme.spacing.sm} * 1) calc(${theme.spacing.sm} * 0.2) calc(${theme.spacing.sm} * 0.2)`,
        },
    },
    accentHead: {
        //backgroundColor: "#161336"
    }
}))

export const orderListFilters = {
    'inProgress': ["NEW", "inProgress", "outsource", "done", "diagnosed", "approval", "waitForPieces", "needAttention"],
    'payed': ['payed'],
    'waitingForPayment': ['done']
}

export type IFilterTypes = 'inProgress' | 'payed' | 'waitingForPayment'

const defaultDates = [
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
]

const Home = () => {
    const {classes} = useStyles();
    const [date, setDate] = useState<[Date | null, Date | null]>([
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    ]);
    const [searchParams, setSearchParams] = useSearchParams({status: "inProgress"})
    const ordersListFilter = searchParams.get('status')

    function onChangeOrdersListFilter(type: IFilterTypes) {
        setSearchParams((prev) => {
            prev.set('status', type)
            return prev;
        }, {replace: true})
    }

    return (
        <div>
            <div className={classes.accentHead}>
                <DatePickerInput
                    type="range"
                    placeholder="Выбирите интервал времени"
                    value={date}
                    onChange={setDate}
                    mx="auto"
                    maw={400}
                    locale={'ru'}
                />
                <Statistics
                    onChangeOrdersListFilter={onChangeOrdersListFilter}
                    ordersListFilter={ordersListFilter as IFilterTypes}
                    readyDate={{
                        "$gt": dayjs(date[0] || defaultDates[0]).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                        "$lt": dayjs(date[1] || defaultDates[1]).format('YYYY-MM-DDT23:59:ss.SSS')
                    }}
                />
            </div>
            <div className={classes.tableContainer}>
                <OrdersTable
                    filter={{
                        readyDate: searchParams.get('status') === 'payed' ? {
                            "$gt": dayjs(date[0] || defaultDates[0]).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                            "$lt": dayjs(date[1] || defaultDates[1]).format('YYYY-MM-DDT23:59:ss.SSS')
                        } : undefined,
                        status: orderListFilters[ordersListFilter as IFilterTypes],
                    }}
                />
            </div>
        </div>
    );
};

Home.propTypes = {};

export default Home;


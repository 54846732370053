import React from "react";
import {Text} from "@mantine/core";

const DateItem = ({data}: { data: Date }) => {
    const currentDate = new Date();
    const createdDate = new Date(data);
    const diffTime = currentDate.getTime() - createdDate.getTime();
    const divide: number = 1000 * 3600 * 24
    const diff = diffTime / divide;

    return (
        <Text color={diff > 3 ? 'red.5' : diff > 2 ? 'orange.5' : 'green.5'} style={{whiteSpace: "nowrap"}}>
            {new Date(data).toLocaleDateString()}
        </Text>
    )
}

export default DateItem;